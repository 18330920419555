* {
    font-family: 'Dosis', sans-serif;
}

#root {
    background-color: white;
}

.body {
    min-height: 710px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.navlink {
    margin: 0px 65px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    margin: 5px 30px;
    color: black;
    font-size: 20px;
    padding: 15px 10px;
    position: relative;
    z-index: 0;
    cursor: pointer;
}

.navlink:hover {
    text-decoration: none;
    color: black;
}

.topBottomBordersOut {
    margin-right: 20px;
}

.topBottomBordersOut a:before, .topBottomBordersOut a:after
{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 3px;
    background: black;
    content: "";
    opacity: 0;
    transition: all 0.3s;
}

.topBottomBordersOut a:before
{
    top: 0px;
    transform: translateY(10px);
}

.topBottomBordersOut a:after
{
    bottom: 0px;
    transform: translateY(-10px);
}

.topBottomBordersOut a:hover:before, .topBottomBordersOut a:hover:after
{
    opacity: 1;
    transform: translateY(0px);
}

.navbar-brand {
    background-color: white;
}

.navbar-brand h3 {
    font-size: 40px;
    margin-bottom: 2px;
    margin-left: -2px;
}

.navbar-brand h5 {
    font-size: 16px;
    margin: 2px;
}

.navbar-brand a {
    color: black;
    text-decoration: underline;
    font-size: 20px;
}

.project {
    width: 350px;
    margin: 15px;
}

.project img {
    width: 100%;
    box-shadow: 3px 3px 6px lightgrey;
    margin: 4px 0px;
}

#technologies {
    font-style: italic;
}

.project-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    animation: opacity 1s forwards;
    animation-delay: .3s;
    margin: 0 50px;
}

.about-me {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: opacity 1s forwards;
    animation-delay: .2s;
}

.about-me img {
    width: 400px;
    margin: 20px;
}

.bio {
    width: 40%;
    margin: 20px;
}

.contact-page {
    margin: 0px 30px;
    width: 50%;
}

.contact-page h2, .contact-page h1 {
    margin: 30px 0px;
}

.download-button {
    color: black;
    padding: 2px 4px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid black;
}

.download-button:hover {
    text-decoration: none;
    color: black;
}

.resume-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    margin: 80px;
    opacity: 0;
    animation: opacity 1s forwards;
}


input {
    width: 400px;
}

.resume-category {
    text-align: center;
}

#visit a {
    color: black;
}

.category-label {
    background-color: rgb(211,211,211,.2);
    padding: 3px;
    margin-bottom: 15px;
    font-size: 40px;
    font-weight: normal;
    animation: bold 1s;
    border-radius: 5px;
    border: 3px dotted grey;
}

.footer {
    background-color: black;
    color: white;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 30px;
    left: 0px;
    bottom: 0px;
}

.social {
    font-size: 50px;
}

.social a {
    color: white;
    margin: 0px 10px;
}

.social a:hover {
    background-color: white;
    color: black;
}

@media (max-width: 600px) {

    .body {
        align-items: flex-start;
        margin-top: 15px;
    }

    .social {
        font-size: 30px;
        display: flex;
        justify-content: space-between;
    }

    .download-button {
        padding: 1px 2px;
        font-size: 20px;
    }

    .resume-page {
        margin: 20px;
        justify-content: flex-start;
    }

    .resume-page h3 {
        font-size: 20px;
    }

    .resume-page h2 {
        font-size: 24px;
    }

    .about-me {
        display: flex;
        flex-direction: column;
    }

    .about-me img {
        width: 300px;
        margin: 5px;
    }

    .bio {
        width: 90%;
        font-size: 14px;
        margin: 3px;
    }
}

.sitename-container {
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: opacity 1s forwards;
    animation-delay: .3s;
    align-items: flex-start;
}

.sitename-container img {
    width: 80%;
    box-shadow: 3px 3px 20px rgb(0,0,0,.2);
    margin: 20px 0px;
}

.project a {
    font-size: 18px;
    color: black;
    text-decoration: underline;
}

.nav-bar {
    background-color: white;
    box-shadow: 3px 3px 6px lightgrey;
}
/*
#download {
opacity: 0;
animation-name: opacity;
animation-duration: .5s;
animation-fill-mode: forwards;
animation-delay: .3s;
}

#visit {
opacity: 0;
animation-name: opacity;
animation-duration: .5s;
animation-fill-mode: forwards;
animation-delay: .6s;
}

#email {
opacity: 0;
animation-name: opacity;
animation-duration: .5s;
animation-fill-mode: forwards;
animation-delay: .8s;
} */

#email a {
    color: black;
    text-decoration: underline;
}

@keyframes opacity {
    to {
        opacity: 1;
    }
}


.slide {
    width: 600px;
}

.carousels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.3);
}

div.modal-body {
    padding: 0px;
}

ol.carousel-indicators {
    margin: 0px;
}

div.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    max-width: 600px;
}

.thumbnail {
    width: 20%;
    margin: 10px;
}

span.carousel-control-next-icon, span.carousel-control-prev-icon {
    width: 20px;
    height: 62px;
    opacity: 1;
    background-color: lightgrey;
    border-radius: 3px;

}

span.carousel-control-next-icon {
    margin-left: 60px;
}

span.carousel-control-prev-icon {
    margin-right: 60px;
}
